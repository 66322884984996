import React, { useState, useEffect } from 'react';
import './App.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Container, Grid, Paper, Typography, Button, TextField, Box, Card, CardHeader, Avatar } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { styled } from '@mui/system';

const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: '25px',
  padding: '10px 25px',
  textTransform: 'none',
  boxShadow: '0 4px 8px 2px rgba(0, 0, 0, .2)',
  fontWeight: 'bold',
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
  },
}));

function App() {
  const [botStatus, setBotStatus] = useState("Неизвестно");
  const [countdown, setCountdown] = useState(null);
  const [settings, setSettings] = useState({
    spread_threshold: 0.003,
    funding_rate_threshold: 0.01,
    order_amount: 9,
    symbol: "RAD/USDT",
    exchange: "bybit",
    time_before_cycle_end: 2,
    time_after_cycle_start: 0.01
  });
  const [exchanges, setExchanges] = useState([]);

  useEffect(() => {
    const fetchExchanges = async () => {
      try {
        const response = await fetch('https://m.rox.su/api/exchanges');
        if (!response.ok) {
          throw new Error('Ошибка: конечная точка /exchanges не найдена.');
        }
        const data = await response.json();
        setExchanges(data);
      } catch (error) {
        console.error('Ошибка при получении списка бирж:', error);
      }
    };

    const fetchSettings = async () => {
      try {
        const response = await fetch('https://m.rox.su/api/settings');
        if (!response.ok) {
          throw new Error('Ошибка: конечная точка /settings не найдена.');
        }
        const data = await response.json();
        setSettings(data);
      } catch (error) {
        console.error('Ошибка при получении настроек:', error);
      }
    };

    fetchExchanges();
    fetchSettings();

    
  }, []);

  // Новый эффект для периодического запроса состояния бота и обратного отсчета
  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const response = await fetch('https://m.rox.su/api/status');
        const data = await response.json();
        setBotStatus(data.running ? "Запущен" : "Остановлен");

        const countdownResponse = await fetch('https://m.rox.su/api/countdown');
        const countdownData = await countdownResponse.json();
        setCountdown(countdownData.time_until_open);  // Обновляем состояние обратного отсчета
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    }, 1000);

    return () => clearInterval(interval);  // Очистка интервала при размонтировании компонента
  }, []);

  const handleStart = async () => {
    try {
      const response = await fetch('https://m.rox.su/api/start', { method: 'POST' });
      if (!response.ok) {
        throw new Error('Ошибка при запуске бота');
      }
      const data = await response.json();
      toast.success(data.message);
      setBotStatus("Запущен");
    } catch (error) {
      console.error('Ошибка:', error);
      toast.error('Ошибка при запуске бота');
    }
  };

  const handleStop = async () => {
    try {
      const response = await fetch('https://m.rox.su/api/stop', { method: 'POST' });
      if (!response.ok) {
        throw new Error('Ошибка при остановке бота');
      }
      const data = await response.json();
      toast.success(data.message);
      setBotStatus("Остановлен");
    } catch (error) {
      console.error('Ошибка:', error);
      toast.error('Ошибка при остановке бота');
    }
  };

  const handleStatus = async () => {
    try {
      const response = await fetch('https://m.rox.su/api/status');
      if (!response.ok) {
        throw new Error('Ошибка при получении статуса бота');
      }
      const data = await response.json();
      setBotStatus(data.running ? "Запущен" : "Остановлен");
    } catch (error) {
      console.error('Ошибка:', error);
      toast.error('Ошибка при получении статуса бота');
    }
  };

  const handleSettingsChange = (e) => {
    setSettings({ ...settings, [e.target.name]: e.target.type === 'number' ? parseFloat(e.target.value) : e.target.value });
  };

  const handleUpdateSettings = async () => {
    try {
      const response = await fetch('https://m.rox.su/api/settings', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(settings)
      });
      if (!response.ok) {
        throw new Error('Ошибка: конечная точка /settings не найдена.');
      }
      const data = await response.json();
      toast.success(data.message);
    } catch (error) {
      console.error('Ошибка:', error);
      toast.error('Ошибка при обновлении настроек');
    }
  };

  return (
    <Container maxWidth="lg">
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <Typography variant="h3" align="center" gutterBottom>
        Управление Торговым Ботом
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} className="card">
            <Box p={3}>
              <Typography variant="h5" gutterBottom>
                Статус Бота
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Текущий статус:</strong> {botStatus}<br></br>
                {countdown !== null ? (
                  <span>До открытия позиции: {countdown.toFixed(2)} секунд</span>
                ) : (
                  <span>Обратный отсчет недоступен</span>
                )}
              </Typography>
              

              <Box mt={2} display="flex" justifyContent="space-between" gap={3}>
                <CustomButton variant="contained" color="primary" onClick={handleStart}>Запустить Бота</CustomButton>
                <CustomButton variant="contained" color="secondary" onClick={handleStop}>Остановить Бота</CustomButton>
                <CustomButton variant="outlined" color="default" onClick={handleStatus}>Обновить Статус</CustomButton>
              </Box>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={3} className="card">
            <Box p={3}>
              <Typography variant="h5" gutterBottom>
                Настройки
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    label="Спред (например, 0.003 = 0.3%)"
                    type="number"
                    name="spread_threshold"
                    value={settings.spread_threshold}
                    onChange={handleSettingsChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    label="Ставка фин-ия (например, 0.01 = 1%)"
                    type="number"
                    name="funding_rate_threshold"
                    value={settings.funding_rate_threshold}
                    onChange={handleSettingsChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    label="Объем ордера (USDT)"
                    type="number"
                    name="order_amount"
                    value={settings.order_amount}
                    onChange={handleSettingsChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    label="Тикер (Символ)"
                    type="text"
                    name="symbol"
                    value={settings.symbol}
                    onChange={handleSettingsChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    label="Биржи (разделены запятыми)"
                    type="text"
                    name="exchange"
                    value={settings.exchange}
                    onChange={handleSettingsChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    label="Время до окончания цикла (секунды)"
                    type="number"
                    name="time_before_cycle_end"
                    value={settings.time_before_cycle_end}
                    onChange={handleSettingsChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    label="Время после начала цикла (секунды)"
                    type="number"
                    name="time_after_cycle_start"
                    value={settings.time_after_cycle_start}
                    onChange={handleSettingsChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomButton variant="contained" color="primary" onClick={handleUpdateSettings} fullWidth>
                    Обновить Настройки
                  </CustomButton>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={3} className="card">
            <Box p={3}>
              <Typography variant="h5" gutterBottom>
                Подключенные Биржи
              </Typography>
              <Grid container spacing={2}>
                {exchanges.length > 0 ? (
                  exchanges.map((exchange, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Card>
                        <CardHeader
                          avatar={
                            <Avatar>
                              <AccountCircle />
                            </Avatar>
                          }
                          title={exchange}
                        />
                      </Card>
                    </Grid>
                  ))
                ) : (
                  <Typography variant="body1" gutterBottom>
                    Нет подключенных бирж
                  </Typography>
                )}
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default App;
